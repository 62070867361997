import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { IMembershipPayment, ISignUp } from '../../models/auth/signUp';
import { ISignInResponse } from '../../models/auth/signIn';
import { IChangePassword, IUser } from '../../models/account/account';
import { Subject } from 'rxjs/internal/Subject';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root',
})

export class AuthService{

  constructor(
    private http: HttpClient) {
  }

  subject = new Subject<any>();

  signIn(mail: string, password: string): Observable<any> {
    const payload = {
      Mail: mail,
      Password: password,
      SiteId: 1,
      //Channel: "web"
    };
    return this.http.post<any>(environment.api + 'user/signin', payload);
  }

  signInForcs(P: string, Pid: string): Observable<any> {
    const payload = {
      p: P,
      pid: Pid
    };
    return this.http.post<any>(environment.api + 'user/signinforcs', payload);
  }

  setAuthInformation(value: ISignInResponse | null) {
    this.subject.next(value);
  }

  getAuthInformation(){
    return this.subject;
  }

  signUp(payload: ISignUp): Observable<any> {
    return this.http.post<any>(environment.api + 'user/signup', payload);
  }

  getCurrentUser(): Observable<any> {
    return this.http.get<any>(environment.api + 'user/getcurrentuser');
  }

  updateCurrentUserData(payload: IUser): Observable<any> {
    return this.http.post<any>(environment.api + 'contactinfo/update', payload);
  }

  getCurrentUserAddress(): Observable<any> {
    return this.http.get<any>(environment.api + 'user/getcurrentuseraddress');
  }

  logout(): Observable<any> {
    return this.http.get<any>(environment.api + 'user/signout');
  }

  changePassword(payload: IChangePassword): Observable<any>{
    return this.http.post<any>(environment.api + 'user/changepassword', payload);
  }

  contactHandler(payload: any): Observable<any> {
    return this.http.post<any>(environment.api + 'user/contact', payload);
  }

  subscribeHandler(email: string): Observable<any> {
    return this.http.post<any>(environment.api + 'user/subscribe', {Email: email});
  }

  membershipPaymentHandler(payload: IMembershipPayment): Observable<any> {
    return this.http.post<any>(environment.api + 'payment/membership', payload);
  }

  advantageMembershipPaymentHandler(payload: IMembershipPayment): Observable<any> {
    return this.http.post<any>(environment.api + 'payment/advantagemembership', payload);
  }
  

  forgotPassword(payload: any): Observable<any> {
    return this.http.post<any>(environment.api + 'user/forgotpassword', payload);
  }

  newPassword(payload: any): Observable<any> {
    return this.http.post<any>(environment.api + 'user/newpassword', payload);
  }

  mainPageWarning(): Observable<any> {
    return this.http.get<any>(environment.api + 'parameter/mainpagewarning');
  }

}
